var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-2"},[_c('div',[_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2 noprint",attrs:{"size":"sm"},on:{"click":_vm.DownloadEvaluationsEgressProfileXLS}},[_c('b-icon-download',{staticClass:"mr-1 icon-download"}),_vm._v(" EVALUACIONES XLS ")],1)],1)]),_c('div',{staticClass:"mt-3"},[[_c('div',{staticClass:"learning-experiences-container"},[_c('b-table-simple',{staticClass:"txt-custom",attrs:{"bordered":"","no-border-collapse":"","small":"","fixed":"","responsive":"","sticky-header":"1000px"}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center primary-header",attrs:{"colspan":"14"}},[_vm._v("Evaluaciones")])],1),_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Nombre de la Asignatura")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Nivel")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Tipo de evaluación")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Resultado de aprendizaje")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Indicadores de Logro")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Situación evaluativa")]),_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  'Descripción evaluación (Mencionar las características principales que configuran la actividad de evaluación: características principales del encargo, ejecución práctica, pruebas escritas o presentación)'
                ),expression:"\n                  'Descripción evaluación (Mencionar las características principales que configuran la actividad de evaluación: características principales del encargo, ejecución práctica, pruebas escritas o presentación)'\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"text-center align-middle secondary-header"},[_vm._v("Descripción evaluación ")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Agente evaluativo")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Ponderación evaluación")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Horas de Docencia Dirigida (pedagógicas)")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Horas de Trabajo Autónomo Preparación y/o Ejecución (cronológicas)")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Tipo de trabajo")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Ambiente")]),_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v("Examinador/a")])],1)],1),_c('b-tbody',[(_vm.matter_evaluations_by_egress_profile.length > 0)?[_vm._l((_vm.matter_evaluations_by_egress_profile),function(matter,index){return _vm._l((matter.matter_evaluations),function(matter_evaluation,index2){return _c('b-tr',{key:'matter' +
                    index +
                    'matter_evalaution ' +
                    matter_evaluation.id +
                    index2},[(index2 == 0)?_c('b-td',{staticClass:"text-left align-middle",attrs:{"rowspan":matter.matter_evaluations.length}},[_vm._v(" "+_vm._s(matter.matter_name)+" ")]):_vm._e(),(index2 == 0)?_c('b-td',{staticClass:"text-center align-middle",attrs:{"rowspan":matter.matter_evaluations.length}},[_vm._v(" "+_vm._s(matter.matter_plan_level)+" ")]):_vm._e(),_c('b-td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(matter_evaluation.evaluation_type)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(matter_evaluation.learning_results)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(matter_evaluation.evaluation_criterias_micro)+" ")]),_c('b-td',{staticClass:"text-left"},[_c('div',[_vm._v(" "+_vm._s(matter_evaluation.evaluation_situations.join(", "))+" ")])]),_c('b-td',[_c('div',{staticClass:"rich-text-content description-evaluation",domProps:{"innerHTML":_vm._s(matter_evaluation.description)}})]),_c('b-td',{staticClass:"text-left"},[_c('div',[_vm._v(" "+_vm._s(matter_evaluation.evaluative_agents.join(", "))+" ")])]),_c('b-td',[_vm._v(" "+_vm._s(matter_evaluation.weighing)+"%")]),_c('b-td',{staticClass:"text-left"},_vm._l((matter_evaluation.pedagogical_hours.filter(
                        (x) => x.hours > 0
                      )),function(pedagogical_hour,index){return _c('div',{key:index},[_c('p',[_vm._v(" "+_vm._s(pedagogical_hour.hours)+" Hrs. "+_vm._s(pedagogical_hour.study_environment != null ? pedagogical_hour.study_environment : `Sin ${_vm.$getVisibleNames( "mesh.studyenvironment", false, "Ambiente de Aprendizaje" )}`)+" ")])])}),0),_c('b-td',{staticClass:"text-left"},_vm._l((matter_evaluation.chronological_hours.filter(
                        (x) => x.hours > 0
                      )),function(chronological_hour,index){return _c('div',{key:index},[_c('p',[_vm._v(" "+_vm._s(chronological_hour.hours)+" Hrs. "+_vm._s(chronological_hour.study_environment != null ? chronological_hour.study_environment : `Sin ${_vm.$getVisibleNames( "mesh.studyenvironment", false, "Ambiente de Aprendizaje" )}`)+" ")])])}),0),_c('b-td',[_vm._v(" "+_vm._s(matter_evaluation.work_type)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(matter_evaluation.study_environments)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(matter_evaluation.examiner)+" ")])],1)})})]:(_vm.matter_evaluations_by_egress_profile.length == 0)?[_c('b-tr',[_c('b-td',{attrs:{"colspan":"14"}},[_c('strong',[_vm._v(" No hay datos para mostrar ")])])],1)]:_vm._e()],2)],1)],1)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }